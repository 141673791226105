import { FormSelector, PageSelector } from "../../fields";

export const Header = {
  label: "Global Settings: Header",
  name: "settings_header",
  file: "cms/content/settings/header.json",
  fields: [
    { label: "Main Logo", name: "mainLogo", widget: "file" },
    {
      label: "Mega Menus",
      name: "megaMenus",
      widget: "list",
      fields: [
        {
          label: "Title",
          name: "title",
          widget: "text",
          required: false
        },
        {
          label: "Heading",
          name: "heading",
          widget: "text",
          required: false
        },
        { label: "Arrow", name: "arrow", widget: "file" },
        { label: "Arrow Dark", name: "arrowDark", widget: "file" },
        {
          label: "Links",
          name: "links",
          widget: "list",
          fields: [
            {
              label: "Label",
              name: "label",
              widget: "text",
              required: false,
            },
            { label: "Icon", name: "icon", widget: "file" },
            { ...PageSelector, required: false },
            { label: "Image", name: "image", widget: "file" },
            {
              label: "Description",
              name: "description",
              widget: "markdown",
              required: false,
            }
          ]
        },
        {
          label: "Icons",
          name: "icons",
          widget: "list",
          fields: [
            {
              label: "Text",
              name: "text",
              widget: "text",
              required: false,
            },
            {
              label: "Icon",
              name: "icon",
              widget: "file",
            },
            {
              label: "Path",
              name: "path",
              widget: "text",
              required: false,
            },
          ]
        },
        {
          label: "Support Text",
          name: "supportText",
          widget: "text",
          required: false,
        },
      ]
    },
    {
      label: "Links",
      name: "links",
      widget: "list",
      fields: [
        { label: "Label", name: "label", widget: "text", required: false },
        { ...PageSelector, required: false },
      ]
    },
    {
      label: "Small Links",
      name: "smallLinks",
      widget: "list",
      fields: [
        { label: "Label", name: "label", widget: "text", required: false },
        { ...PageSelector, required: false },
        { label: "Url", name: "url", widget: "text", required: false },
        FormSelector,
        {
          label: "Sublinks",
          name: "sublinks",
          widget: "list",
          required: false,
          fields: [
            { label: "Label", name: "label", widget: "text", required: false },
            { ...PageSelector, required: false },
            { label: "Url", name: "url", widget: "text", required: false },
            {
              label: "Icon",
              name: "icon",
              widget: "file",
              default: "/placeholder.png",
            },
            FormSelector
          ]
        },
      ]
    },
    {
      label: "Main Icons",
      name: "mainIcons",
      widget: "list",
      fields: [
        { label: "Link Text", name: "linkText", widget: "text", required: false },
        { label: "Icon", name: "icon", widget: "file" },
        { label: "Link Path", name: "linkPath", widget: "text", required: false },
      ]
    },
    {
      label: "Search Icon",
      name: "searchIcon",
      widget: "file"
    },
    {
      label: "My BTC Links",
      name: "myBtcLinks",
      widget: "list",
      fields: [
        {
          label: "Icon",
          name: "icon",
          widget: "file",
          default: "/placeholder.png"
        },
        {
          label: "Text", name: "text", required: false, widget: "text"
        },
        {
          label: "Url", name: "url", required: false, widget: "text"
        },
      ]
    }
  ]
}
