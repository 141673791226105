const TeamMember = [
  { label: "Full name", name: "fullName", widget: "text" },
  { label: "Image", name: "image", widget: "file", required: false },
  { label: "First Name", name: "firstName", widget: "text" },
  { label: "Last Name", name: "lastName", widget: "text" },
  { label: "Title", name: "title", widget: "text" },
  {
    name: "linkedIn",
    label: "LinkedIn",
    widget: "text",
    required: false,
  },
  {
    name: "email",
    label: "Email",
    widget: "text",
    required: false
  },
  {
    name: "phone",
    label: "Phone",
    widget: "text",
    hint: "Enter the 10-digit phone number with no extra formatting",
    required: false
  },
]

export const TeamMembers = {
  label: "Team Members",
  name: "team_members",
  files: [
    {
      label: "Team Members",
      name: "team_members",
      file: "cms/content/team.json",
      fields: [
        {
          name: "contentTypeTeamMembersManagement",
          label: "Management",
          widget: "list",
          fields: TeamMember
        },
        {
          name: "contentTypeTeamMembersSales",
          label: "Sales",
          widget: "list",
          fields: TeamMember
        },
      ]
    }
  ]
}

