export const SupportArticles = {
  label: "Support Articles",
  name: "support_articles",
  folder: "cms/content/support-articles",
  create: true,
  identifier_field: "issue",
  format: "json",
  fields: [
    { label: "Image", name: "image", widget: "file", required: false },
    { label: "Slug", name: "slug", widget: "text" },
    { label: "Seo Image", name: "seo_image", widget: "file", required: false },
    {
      label: "Tags",
      name: "tags",
      widget: "list",
      required: false
    },
    { 
      label: "Category",
      name: "category",
      widget: "select",
      required: false,
      default: "Business",
      options: [
        "Business",
        "Home",
        "Any"
      ],
      hint: "The target audience for the article"
    },
    {
      label: "Subcategory",
      name: "subcategory",
      widget: "select",
      options: [
        "Billing",
        "Connectivity",
        "General Support",
        "My Account",
        "Troubleshooting"
      ],
      default: "Billing",
      hint: "The subcategory for the support article. Not finding the subcategory you need? Contact us to have it added!"
    },
    {
      label: "Issue",
      name: "issue",
      description: "The issue in the form of a statement. Will show on support CTAs like the element on the bottom of the homepage.",
      widget: "text"
    },
    {
      label: "Question",
      name: "question",
      widget: "text",
      description: "Issue in the form of a question. This will show on the support page."
    },
    {
      label: "Summary",
      name: "summary",
      widget: "text",
      description: "A brief summary of what the article is about."
    },
    { label: "Content", name: "content", widget: "markdown" }
  ]
}