export const DefaultSelectorFields = {
  search_fields: ["title"],
  value_field: "title",
  display_fields: ["title"],
  widget: "relation",
}

export const ArticleSelector = {
  label: "Article",
  name: "article",
  collection: "blog_articles",
  required: false,
  ...DefaultSelectorFields,
  value_field: "slug",
}

export const SupportVideoCategorySelector = {
  label: "Support Video Category",
  name: "supportVideoCategory",
  collection: "support_video_categories",
  required: false,
  value_field: "category",
  search_fields: ["category"],
  display_fields: ["category"],
  widget: "relation",
}

export const FaqCategorySelector = {
  label: "FAQ Category",
  name: "subcategory",
  collection: "faq_categories",
  required: false,
  hint: "Filter for support page faq's",
  ...DefaultSelectorFields
}

export const FileSelector = {
  label: "File",
  name: "file",
  widget: "file",
  required: false,
}

export const HeroImagePosition = {
  label: "Image Position",
  name: "image_position",
  widget: "number",
  required: false,
  hint: "Vertical position away from center. Negative values move the image up, and positive values move the image down. Vertical position will be centered if left blank."
}

export const PageSelector = {
  label: "Page",
  name: "page",
  collection: "pages",
  required: false,
  ...DefaultSelectorFields,
  value_field: "slug",
}

export const PhoneSelector = {
  label: "Phone",
  name: "phone",
  widget: "text",
  required: false,
  hint: "Enter phone number in the format 9181234567 (no dashes or periods)"
}

export const FormSelector = {
  label: "Form",
  name: "form",
  collection: "forms",
  required: false,
  search_fields: ["formTitle"],
  value_field: "formTitle",
  display_fields: ["formTitle"],
  widget: "relation",
}

export const SharedTextButton = {
  label: "Shared: Text Button",
  name: "sharedTextButton",
  widget: "object",
  summary:"{{label}}",
  fields: [
    {
      label: "Label",
      name: "label",
      widget: "text",
      required: false,
    },
    {
      label: "Style",
      name: "style",
      widget: "select",
      options: [
        "Blue fill",
        "Blue outline",
        "Orange fill",
        "White outline"
      ],
      required: false,
    },
    {
      label: "Link type",
      name: "type",
      widget: "select",
      options: [
        "Article",
        "Url",
        "Page",
        "Form",
        "File",
        "Phone"
      ],
      required: false
    },
    PageSelector,
    FormSelector,
    ArticleSelector,
    FileSelector,
    PhoneSelector,
    { label: "Url", name: "url", widget: "text", required: false }
  ]
}

export const SupportSelector = {
  label: "Article",
  name: "article",
  collection: "support_articles",
  required: true,
  search_fields: ["issue","question","summary"],
  value_field: "issue",
  display_fields: ["issue"],
  widget: "relation",
}

export const ZipFields = [
  {
    label: "Text",
    name: "text",
    widget: "text",
    required: false ,
    hint: "Enter any text description here"
  },
  {
    label: "Call to actions",
    name: "callToActions",
    hint: "Create call to actions",
    widget: "list",
    fields: [
      { label: "Text", name: "text", required: false },
      {
        label: "Link type",
        name: "linkType",
        widget: "select",
        required: false,
        options: [
          "page",
          "url",
          "form",
          "phone"
        ],
        hint: "Select the link type of the call to action"
      },
      {
        label: "Phone",
        name: "phone",
        required: false,
        widget: "text",
        hint: "Enter phone here if link type is a phone number."
      },
      {
        label: "Url",
        name: "url",
        required: false,
        widget: "text",
        hint: "Enter url here if link type is an external url."
      },
      {
        ...PageSelector,
        hint: "Select page if call to action links to a page"
      },
      {
        ...FormSelector,
        hint: "Select form if call to action links to a form.",
      },
    ]
  }
]

export const Zip = [
  { label: "Zip", name: "zip", widget: "text", required: false },
  ...ZipFields,
]

export const VideoSelector = {
  label: "Video",
  name: "video",
  collection: "videos",
  required: false,
  search_fields: ["question","title","video"],
  value_field: "title",
  display_fields: ["title"],
  widget: "relation",
}