const Testimonial = [
  { label: "Testimonial", name: "testimonial", widget: "markdown" },
  { label: "Signature", name: "signature", widget: "markdown" },
  {
    label: "Testimonial Type",
    name: "type",
    widget: "select",
    options: ["None","Image","Video Url", "Video File"],
    hint: "What type of media is associated with this testimonial?"
  },
  { label: "Image", name: "image", default: "/placeholder.png", required: false },
  { label: "Video Url", name: "url", required: false, widget: "text" },
  { label: "Video File", name: "video_file", widget: "file", default: "/path.svg", required: false },
]

export const Testimonials = {
  label: "Testimonials",
  name: "testimonials",
  files: [
    {
      label: "Testimonials",
      name: "testimonials",
      file: "cms/content/testimonials.json",
      fields: [
        {
          name: "testimonialsBusiness",
          label: "Business",
          widget: "list",
          fields: Testimonial
        },
        {
          name: "testimonialsResidential",
          label: "Residential",
          widget: "list",
          fields: Testimonial
        },
      ]
    }
  ]
}