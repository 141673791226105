export const BlogArticles = {
  label: "Blog Articles",
  name: "blog_articles",
  folder: "cms/content/blog-articles",
  create: true,
  format: "json",
  fields: [
    { label: "Title", name: "title", widget: "text" },
    { label: "Slug", name: "slug", widget: "text" },
    { label : "Date", name: "date", widget: "datetime", hint: "Date post was created" },
    { label: "Hero Image", name: "heroImage", widget: "file", hint: "Enter hero image for blog post here", default: "/sarah-dorweiler-9Z1KRIfpBTM-unsplash.jpg" },
    { label: "Tags", name: "tags", widget: "list", required: false },
    { label: "Seo Image", name: "seo_image", widget: "file", required: false },
    { label: "Type", name: "type", widget: "text", default: "article" },
    { 
      label: "Category",
      name: "category",
      widget: "select",
      required: false,
      options: [
        "Internet",
        "Fiber",
        "Community",
        "Innovation",
        "WiFi",
        "In the News",
        "Newsletters",
        "Magazines",
        "Business Insights",
        "Residential Insights",
        "Community",
        "Newsroom"
      ]
    },
    { label: "Body", name: "body", widget: "markdown" }
  ]
}