export const Careers = {
  label: "Careers",
  name: "careers",
  folder: "cms/content/careers",
  create: true,
  format: "json",
  fields: [
    { label: "Title", name: "title", widget: "text" },
    { label: "Location", name: "location", widget: "text" },
    { label: "Body", name: "body", widget: "markdown" },
    { label: "Keywords", name: "keywords", widget: "list" },
    { label: "SEO Description", name: "seoDescription", widget: "text" }
  ]
}