export const SupportVideoCategories = {
  label: "Support Video Categories",
  name: "support_video_categories",
  folder: "cms/content/support-video-categories",
  create: true,
  format: "json",
  label_singular: "Support Video Category",
  identifier_field: "category",
  fields: [
    {
      label: "Category",
      name: "category",
      widget: "string",
      hint: "The video category",
    },
    {
      label: "Featured",
      name: "featured",
      collection: "support_videos",
      required: false,
      search_fields: ["title"],
      value_field: "title",
      display_fields: ["title"],
      widget: "relation",
    },
    {
      label: "Videos",
      name: "videos",
      widget: "list",
      fields: [
        {
          label: "Video",
          name: "video",
          collection: "support_videos",
          required: false,
          search_fields: ["title"],
          value_field: "title",
          display_fields: ["title"],
          widget: "relation",
        },
      ]
    }
  ]
}