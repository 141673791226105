const defaultProps = [
  { label: "Label", name: "label", widget: "text" },
  { label: "Placeholder", name: "placeholder", widget: "text", required: false },
]

export const Checkboxes = {
  label: "Checkboxes",
  name: "checkboxes",
  widget: "object",
  summary:"{{label}}",
  fields: [
    ...defaultProps,
    { label: "Options", name: "options", widget: "list" },
  ]
}

export const Dropdown = {
  label: "Dropdown",
  name: "dropdown",
  widget: "object",
  summary:"{{label}}",
  fields: [
    ...defaultProps,
    { label: "Options", name: "options", widget: "list" },
  ]
}

export const Hidden = {
  label: "Hidden",
  name: "hidden",
  widget: "object",
  fields: [
    ...defaultProps,
    { label: "Name", name: "name", widget: "text" },
    { label: "Value", name: "value", widget: "text" },
  ]
}

export const Radio = {
  label: "Radio",
  name: "radio",
  widget: "object",
  summary:"{{label}}",
  fields: [
    ...defaultProps,
    { label: "Options", name: "options", widget: "list" },
  ]
}

export const Slider = {
  label: "Slider",
  name: "slider",
  widget: "object",
  summary:"{{label}}",
  fields: [
    ...defaultProps,
    {
      label: "Min",
      name: "min",
      widget: "text"
    },
    {
      label: "Max",
      name: "max",
      widget: "text"
    },
    {
      label: "Step",
      name: "step",
      widget: "number",
      hint: "The amount the slider is incremented as it moves between min and max"
    },
  ]
}

export const Text = {
  label: "Text",
  name: "text",
  widget: "object",
  summary:"{{label}}",
  fields: [
    ...defaultProps,
  ]
}

export const TextArea = {
  label: "Textarea",
  name: "textarea",
  widget: "object",
  summary:"{{label}}",
  fields: [
    ...defaultProps,
  ]
}

export const FormComponents = [
  Checkboxes,
  Dropdown,
  Hidden,
  Radio,
  Slider,
  Text,
  TextArea
]