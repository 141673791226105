export const SupportVideos = {
  label: "Support Videos",
  name: "support_videos",
  label_singular: "Support Video",
  folder: "cms/content/support-videos",
  create: true,
  format: "json",
  identifier_field: "title",
  fields: [
    { label: "Title", name: "title", widget: "text"},
    { label: "Video URL", name: "videoURl", widget: "text" },
    { label: "Video Thumbnail", name: "thumbnail", widget: "file", default: "/placeholder.png" },
  ]
}