export const FaqCategories = {
  label: "FAQ Categories",
  name: "faq_categories",
  folder: "cms/content/faq-categories",
  create: true,
  format: "json",
  fields: [
    { label: "Label", name: "title", widget: "text"},
    { label: "Description", name: "description", widget: "text" },
    { label: "Icon", name: "icon", widget: "file", default: "/placeholder.png" },
  ]
}