export const Videos = {
  label: "Videos",
  name: "videos",
  folder: "cms/content/videos",
  create: true,
  format: "json",
  fields: [
    {
      label: "Category",
      name: "category",
      widget: "select",
      options: ["Home", "Business", "All"],
      hint: "The target audience for the article.",
      default: "Business"
    },
    {
      label: "Question",
      name: "question",
      widget: "string",
      hint: "Issue in the form of a question. This will show on the support page."
    },
    {
      label: "Subcategory",
      name: "subcategory",
      widget: "select",
      options: [
        "How-to videos"
      ],
      default: "How-to videos",
      hint: "The subcategory for the support article. Not finding the subcategory you need? Contact us to have it added!"
    },
    {
      label: "Issue",
      name: "title",
      widget: "text",
      hint: "The issue in the form of a statement. Will show on support CTAs like the element on the bottom of the homepage."
    },
    {
      label: "Video URL",
      name: "video",
      widget: "text"
    }
  ]
}