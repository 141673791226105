import { DefaultSelectorFields, FaqCategorySelector, FormSelector, HeroImagePosition, PageSelector, SharedTextButton, SupportSelector, SupportVideoCategorySelector, VideoSelector, Zip, ZipFields } from "./fields"

const About = {
  label: "Main: About",
  name: "main-about",
  widget: "object",
  summary: "{{aboutTopImage}}",
  fields: [
    { label: "Top Image", name: "aboutTopImage", widget: "file", default: "/placeholder.png" },
    { label: "Wave Image", name: "waveImage", widget: "file", default: "/path.svg" },
    { label: "Top Title", name: "blockTitle", widget: "text" },
    { label: "Video Url", name: "videoUrl", widget: "text", required: false },
    { label: "Top Body", name: "aboutTopBody", widget: "markdown" },
    {
      label: "Social Links", 
      name: "socialLinks",
      widget: "list",
      fields: [
        { label: "Label", name: "label", widget: "text", required: false },
        { label: "Icon", name: "icon", widget: "file" },
        { label: "Url", name: "url", widget: "text", required: false }
      ]
    },
    { label: "Bottom Image", name: "aboutBottomImage", widget: "file", default: "/placeholder.png" },
    { label: "Bottom Title", name: "aboutBottomTitle", widget: "text" },
    { label: "Bottom Body", name: "aboutBottomBody", widget: "markdown" },
    {
      label: "Bottom Buttons",
      name: "aboutBottomButtons",
      widget: "list",
      fields: [
        SharedTextButton
      ]
    }
  ]
}

const BandwidthEstimator = {
  label: "Main: Bandwidth Estimator",
  name: "main-bandwidth-estimator",
  widget: "object",
  summary: "{{bandwidthEstimatorSubtitle}}",
  fields: [
    { label: "Title", name: "blockTitle", widget: "text" },
    { label: "Subtitle", name: "bandwidthEstimatorSubtitle", widget: "text" },
    { label: "Slider One Text", name: "bandwidthEstimatorSliderOneText", widget: "text", required: false },
    { label: "Slider One Icon", name: "bandwidthEstimatorSliderOneIcon", widget: "file", required: false },
    { label: "Slider Two Text", name: "bandwidthEstimatorSliderTwoText", widget: "text", required: false },
    { label: "Slider Two Icon", name: "bandwidthEstimatorSliderTwoIcon", widget: "file", required: false },
    { label: "Yes No Text", name: "bandwidthEstimatorYesNoText", widget: "text", required: false },
    { label: "Yes No Icon", name: "bandwidthEstimatorYesNoIcon", widget: "file" },
    { label: "Text", name: "bandwidthEstimatorText", widget: "markdown", required: false },
    { label: "Disclaimer", name: "disclaimer", widget: "text" },
    { label: "Bandwidth Icon", name: "bandwidthEstimatorIcon", widget: "file" },
    { label: "Footer Title", name: "bandwidthEstimatorFooterTitle", widget: "text", required: false },
    { label: "Footer Subtitle", name: "bandwidthEstimatorFooterSubtitle", widget: "text", required: false }
  ]
}

const Banner = {
  label: "Main: Banner",
  name: "main-banner",
  summary: "{{blockTitle}}",
  widget: "object",
  fields: [
    { label: "Title", name: "blockTitle", widget: "text" },
    { label: "Subtitle", name: "bannerSubtitle", widget: "text", required: false },
    { label: "Footer Text", name: "footerText", widget: "text", required: false },
    {...SharedTextButton, label: "Button" }
  ]
}

const Blog = {
  label: "Main: Blog",
  name: "main-blog",
  widget: "object",
  fields: [
    {
      label: "Featured Article",
      name: "blogFeaturedArticle",
      collection: "blog_articles",
      required: true,
      ...DefaultSelectorFields,
    },
    {
      label: "Categories",
      name: "categories",
      widget: "list",
    }
  ]
}

const BusinessInternetSpeeds = {
  label: "Main: Business Internet Speeds",
  name: "main-business-internet-speeds",
  summary: "{{businessInternetSpeedsText}}",
  widget: "object",
  fields: [
    { label: "Title", name: "blockTitle", widget: "text" },
    { label: "Text", name: "businessInternetSpeedsText", widget: "text", required: false },
    { label: "Information", name: "businessInternetSpeedsInformation", widget: "text", required: false },
    { label: "Footer Message", name: "businessInternetSpeedsFooterMessage", widget: "text", required: false },
    {...SharedTextButton, label: "Footer Button" },
    {
      label: "Buttons",
      name: "businessInternetSpeedsButtons",
      widget: "list",
      fields: [
        SharedTextButton
      ]
    },
    {
      label: "Speeds",
      name: "businessInternetSpeedsSpeeds",
      widget: "list",
      fields: [
        { label: "Download Speed", name: "downloadSpeed", widget: "number", default: 0 },
        { label: "Upload Speed", name: "uploadSpeed", widget: "number", default: 0 },
      ]
    },
    { label: "Arrow Up", name: "arrowUp", widget: "file" },
    { label: "Arrow Down", name: "arrowDown", widget: "file" },
  ]
}

export const BusinessMap = {
  label: "Main: Business Map",
  name: "main-business-map",
  widget: "object",
  fields: [
    { label: "Business Map Title", name: "blockTitle", widget: "text" },
    { label: "Coverage Footer Text", name: "coverageFooterText", widget: "text" },
    {
      label: "Zip codes",
      name: "zipCodes",
      widget: "list",
      required: false,
      fields: [
        ...Zip,
      ]
    },
    {
      label: "Other zips",
      name: "otherZips",
      widget: "object",
      required: false,
      fields: [
        ...ZipFields,
      ]
    },
    {
      label: "Outside State",
      name: "outOfStateZips",
      widget: "object",
      required: false,
      fields: [
        ...ZipFields,
      ]
    }
  ]
}

const Careers = {
  label: "Main: Careers",
  name: "main-careers",
  widget: "object",
  fields: [
    {
      label: "Careers",
      name: "careers",
      widget: "list",
      fields: [
         { label: "Title", name: "careersTitle", widget: "text" },
         { label: "Location", name: "careersLocation", widget: "text" },
         { label: "Url", name: "careersUrl", widget: "text" }
      ]
    }
  ]
}

const CloudCommunications = {
  label: "Main: Cloud Communications",
  name: "main-cloud-communications",
  widget: "object",
  summary: "{{cloudCommunicationsText}}",
  fields: [
    { label: "Image", name: "cloudCommunicationsImage", widget: "file", default: "/placeholder.png" },
    { label: "Title", name: "blockTitle", widget: "text" },
    {
      label: "Icons",
      name: "cloudCommunicationsIcons",
      widget: "list",
      fields: [
        { label: "Icon", name: "icon", widget: "file", default: "/placeholder.png" },
        {label: "Label", name: "label", widget: "text" },
      ]
    },
    {
      label: "Text", name: "cloudCommunicationsText", widget: "markdown"
    }
  ]
}

const CloudPlans = {
  label: "Main: Cloud Plans",
  name: "main-cloud-plans",
  summary: "{{cloudPlansSubHeading}}",
  widget: "object",
  fields: [
    { label: "Title", name: "blockTitle", widget: "text", required: false },
    { label: "Subtitle", name: "cloudPlansSubHeading", widget: "text", required: false },
    { label: "Description", name: "cloudPlansDescription", widget: "markdown", required: false },
    {
      label: "Cloud Plans Options",
      name: "cloudPlansOptions",
      widget: "list",
      fields: [
        { label: "Option", name: "option", widget: "text", required: false },
        { label: "Plans", name: "plans", widget: "list" },
      ]
    },
    {
      label: "Cloud Plans Plans",
      name: "cloudPlansPlans",
      widget: "list",
      fields: [
        { label: "Title", name: "title", widget: "text", required: false },
        { label: "Price", name: "price", widget: "text", required: false },
        { label: "Price Suffice", name: "priceSuffix", widget: "text", required: false },
        SharedTextButton
      ]
    },
    {
      label: "Footer Text", name: "footerText", widget: "text", required: false,
    },
    SharedTextButton,
    {
      label: "Footer SubText", name: "footerSubtext", widget: "text", required: false,
    }
  ]
}

const CommandIq = {
  label: "Main: CommandIQ",
  summary: "{{heading}}",
  name: "main-commandiq",
  widget: "object",
  fields: [
    { label: "Heading", name: "heading", widget: "text", required: false },
    { label: "Text", name: "text", widget: "markdown", required: false },
    { label: "main image", name: "main_image", widget: "file" },
    {
      label: "list of icons",
      name: "list_of_icons",
      widget: "list",
      fields: [
        { label: "Icon", name: "icon", widget: "file" },
        { label: "Icon Heading", name: "icon_heading", widget: "text", required: false },
        { label: "icon body text", name: "icon_body_text", widget: "markdown", required: false },
      ]
    },
    { label: "caption", name: "caption", widget: "markdown", required: false },
    {
      label: "app store icons",
      name: "appStoreIcons",
      widget: "list",
      fields: [
        { label: "icon", name: "icon", widget: "file" },
        { label: "url", name: "url", widget: "text", required: false }
      ]
    }
  ]
}

const Contact = {
  label: "Main: Contact",
  name: "main-contact",
  widget: "object",
  fields: [
    { label: "Title", name: "blockTitle", widget: "text" },
    { label: "Body", name: "body", widget: "markdown" },
    { label: "Ticket Status Text", name: "contactTicketStatusText", widget: "text", required: false },
    { ...SharedTextButton, label: "Button", required: false }
  ]
}

const Coverage = {
  label: "Main: Coverage",
  name: "main-coverage",
  summary: "{{blockTitle}}",
  widget: "object",
  fields: [
    { name: "blockTitle", label: "Coverage Title", widget: "text" },
    { name: "coverageFooterText", label: "Coverage Footer Text", widget: "text" },
    { ...SharedTextButton, label: "Coverage Footer Button"},
    {
      name: "service_links",
      label: "Service Links",
      hint: "Links to service",
      widget: "list",
      fields: [
        
        { name: "label", label: "Label", widget: "text", hint: "Link description" },
        { ...PageSelector, required: true, hint: "Page Link" }
      ]
    }
  ]
}

const Details = {
  label: "Main: Details",
  name: "main-details",
  widget: "object",
  fields: [
    { label: "Heading", name: "heading", widget: "text" },
    { label: "text", name: "text", widget: "markdown", required: false }
  ]
}

const Devices = {
  label: "Main: Devices",
  name: "main-devices",
  widget: "object",
  fields: [
    {
      label: "Devices",
      name: "devices",
      widget: "list",
      fields: [
        { label: "Image", name: "devicesImage", widget: "file" },
        { label: "Model", name: "devicesModel", widget: "text", required: false },
        { label: "bluetooth", name: "devicesBluetooth", widget: "boolean" },
        { label: "Wifi", name: "devicesWifi", widget: "boolean" },
        { label: "Usb", name: "devicesUsb", widget: "boolean" },
        { label: "Spec sheet", name: "deviceSpecs", widget: "file" },
        { label: "Brand logo", name: "deviceBrandLogo", widget: "file" },
        { ...SharedTextButton, label: "Button" }
      ]
    }
  ]
}

const FeatureChart = {
  label: "Main: Feature Chart",
  summary: "{{featureChartHeader}}",
  name: "main-feature-chart",
  widget: "object",
  fields: [
    { label: "Title", name: "featureChartTitle", widget: "text" },
    { label: "Header", name: "featureChartHeader", widget: "text" },
    { label: "Header Features", name: "featureChartHeaderFeatures", widget: "list" },
    {
      label: "Plans",
      name: "featurChartPlans",
      widget: "list",
      fields: [
        { label: "Plan Name", name: "plan_name", widget: "text" },
        { label: "Price", name: "price", widget: "number" },
      ]
    },
    {
      label: "Chart Features",
      name: "featureChartChartFeatures",
      widget: "list",
      fields: [
        { label: "Feature", name: "featureChartFeature", widget: "text" },
        {
          label: "Plans",
          name: "plans",
          widget: "list",
          fields: [
            { label: "Feature Included", name: "featureIncluded", widget: "boolean" }
          ]
        }
      ]
    },
    { label: "Footer", name: "footer", widget: "text" },
    { label: "Footer Features", name: "featureCharFooterFeatures", widget: "list" }
  ]
}

const FeatureColumns = {
  label: "Main: Feature Columns",
  name: "main-feature-columns",
  summary: "{{blockTitle}}",
  widget: "object",
  fields: [
    { label: "Heading", name: "blockTitle", widget: "markdown" },
    { label: "Subheading", name: "featureColumnsSubheading", widget: "markdown", required: false },
    {
      label: "Columns",
      name: "featureColumnsColumns",
      widget: "list",
      fields: [
        { label: "Title", name: "title", widget: "text" },
        { label: "Popular Tab", name: "popularTab", widget: "boolean" },
        { label: "Icon", name: "icon", widget: "file", default: "/placeholder.png" },
        { label: "Disclaimer", name: "disclaimer", widget: "markdown", required: false },
        { label: "Description", name: "description", required: false, widget: "markdown" },
        { label: "Highlight Prefix", name: "highlightPrefix", widget: "text", required: false },
        { label: "Highlight", name: "highlight", widget: "text", required: false },
        { label: "Highlight Suffix", name: "highlightSuffix", widget: "text", required: false },
        { label: "Footer", name: "footer", widget: "text", required: false },
        { ...SharedTextButton, label: "Button" },
        {
          label: "Buttons",
          name: "buttons",
          widget: "list",
          fields: [
            SharedTextButton,
          ]
        },
      ]
    },
    { label: "Footer", name: "featureColumnsFooter", widget: "markdown", required: false },
    {
      label: "Buttons",
      name: "featureColumnsButtons",
      widget: "list",
      fields: [
        SharedTextButton,
      ]
    }
  ]
}

const FeatureTabs = {
  label: "Main: Feature Tabs",
  name: "main-feature-tabs",
  summary: "{{blockTitle}}",
  widget: "object",
  fields: [
    { label: "Title", name: "blockTitle", widget: "text" },
    { label: "Image", name: "featureTabsImage", widget: "file" },
    {
      label: "Tabs",
      name: "featureTabsTabs",
      widget: "list",
      fields: [
        { label: "Icon", name: "icon", widget: "file" },
        { label: "Title", name: "title", widget: "text", required: false },
        { label: "Text", name: "text", widget: "markdown" },
      ]
    },
    {
      label: "Buttons", name: "featureTabsButtons", widget: "list", fields: [
        SharedTextButton
      ]
    }
  ]
}

const Feedback = {
  label: "Main: Feedback",
  name: "main-feedback",
  summary: "{{feedbackSubtitle}}",
  widget: "object",
  fields: [
    { label: "Title", name: "blockTitle", widget: "text" },
    { label: "Subtitle", name: "feedbackSubtitle", widget: "text" },
    {
      label: "Feedback",
      name: "feedback",
      widget: "list",
      fields: [
        { label: "First Name", name: "feedbackFirstName", widget: "text" },
        { label: "Last Name", name: "feedbackLastName", widget: "text" },
        {
          label: "Feedback Type",
          name: "feedbackFeedbackType",
          widget: "select",
          required: false,
          options: [ "Business", "Residential" ]
        },
        { label: "Company", name: "company", widget: "text", required: false },
        { label: "Location", name: "feedbackLocation", widget: "text", required: false },
        { label: "Message", name: "feedbackMessage", widget: "markdown" },
      ]
    },
    { label: "Feedback Invitiation", name: "feedbackFeedbackInvitation", widget: "markdown" },
    { ...SharedTextButton, label: "Button" }
  ]
}

const FullPageText = {
  label: "Main: Full Page Text",
  name: "main-full-page-text",
  widget: "object",
  fields: [
    { label: "text", name: "text", widget: "markdown", required: false }
  ]
}

const HeroWithLogo = {
  label: "Main: Hero With Logo",
  name: "main-hero-with-logo",
  summary: "{{blockTitle}}",
  widget: "object",
  fields: [
    { label: "Image", name: "heroImage", widget: "file", default: "/placeholder.png" },
    {
      label: "Image Width",
      name: "image_width",
      widget: "number",
      required: false,
      hint: "The percentage width of the container. Can be any number, even greater than 100. Leave at 0 to have the image just fill the container (default setting)",
      default: 0,
    },
    HeroImagePosition,
    { label: "Show Page Title", name: "heroShowPageTitle", widget: "boolean" },
    { label: "Hero Logo", name: "heroLogo", widget: "file", default: "/placeholder.png" },
    { label: "Title", name: "blockTitle", widget: "markdown", hint: "You may reference the user's city by entering {{city}}" },
    {
      label: "Title Size",
      name: "heroTitleSize",
      widget: "select",
      options: [
        "Small",
        "Medium",
        "Large"
      ],
      required: false
    },
    { label: "Body", name: "heroBody", widget: "markdown", required: false },
    {
      label: "Buttons",
      name: "heroButtons",
      widget: "list",
      fields: [
        { ...SharedTextButton, label: "Button" },
      ]
    }
  ]
}

const Hero = {
  label: "Main: Hero",
  name: "main-hero",
  summary: "{{blockTitle}}",
  widget: "object",
  fields: [
    { label: "Image", name: "heroImage", widget: "file", default: "/placeholder.png" },
    {
      label: "Image Width",
      name: "image_width",
      widget: "number",
      required: false,
      hint: "The percentage width of the container. Can be any number, even greater than 100. Leave at 0 to have the image just fill the container (default setting)",
      default: 0,
    },
    HeroImagePosition,
    { label: "Show Page Title", name: "heroShowPageTitle", widget: "boolean" },
    { label: "Hero Small Title", name: "heroSmallTitle", widget: "markdown", required: false },
    { label: "Title", name: "blockTitle", widget: "markdown", hint: "You may reference the user's city by entering {{city}}" },
    {
      label: "Title Size",
      name: "heroTitleSize",
      widget: "select",
      options: [
        "Small",
        "Medium",
        "Large"
      ],
      required: false
    },
    { label: "Body", name: "heroBody", widget: "markdown", required: false },
    {
      label: "Buttons",
      name: "heroButtons",
      widget: "list",
      fields: [
        { ...SharedTextButton, label: "Button" },
      ]
    }
  ]
}

const HubspotForm = {
  label: "Main: Hubsport Form",
  name: "main-hubspot-form",
  widget: "object",
  fields: [
    { label: "Portal ID", name: "portalId", widget: "text" },
    { label: "Form ID", name: "formId", widget: "text" }
  ]
}

const Map = {
  label: "Main: Map",
  name: "main-map",
  widget: "object",
  fields: [
    { label: "Search Label", name: "mapSearchLabel", widget: "text" },
    { label: "Footer Text", name: "mapFooterText", widget: "markdown", required: false },
    { ...SharedTextButton, label: "Button" },
  ]
}

const PageForm = {
  label: "Main: Page Form",
  name: "main-page-form",
  widget: "object",
  fields: [
    FormSelector
  ]
}

const Promotions = {
  label: "Main: Promotions",
  name: "main-promotions",
  widget: "object",
  fields: [
    {
      label: "Promotions",
      name: "promotionsPromotions",
      widget: "list",
      fields: [
        { label: "Title", name: "title", widget: "text" },
        { label: "Image", name: "image", widget: "file" },
        HeroImagePosition,
        {
          label: "Image Width",
          name: "image_width",
          widget: "number",
          required: false,
          hint: "The percentage width of the container. Can be any number, even greater than 100. Leave at 0 to have the image just fill the container (default setting)",
          default: 0,
        },
        { label: "Body", name: "body", widget: "markdown" },
        {
          label: "Buttons",
          name: "buttons",
          widget: "list",
          fields: [
            SharedTextButton
          ]
        }
      ]
    }
  ]
}

const ResidentialInternet = {
  label: "Main: Residential Internet",
  name: "main-residential-internet",
  summary: "{{residentialInternetBody}}",
  widget: "object",
  fields: [
    { label: "Title", name: "blockTitle", widget: "text" },
    { label: "Body", name: "residentialInternetBody", widget: "markdown" },
    {
      label: "Buttons",
      name: "residentialInternetButtons",
      widget: "list",
      fields: [
        SharedTextButton,
      ]
    },
    { label: "Image", name: "residentialInternetImage", widget: "file" }
  ]
}

const ResidentialMap = {
  label: "Main: Residential Map",
  name: "main-residential-map",
  widget: "object",
  fields: [
    { label: "Residential Map Title", name: "blockTitle", widget: "text" },
    { label: "Coverage Footer Text", name: "coverageFooterText", widget: "text" },
    {
      label: "Zip codes",
      name: "zipCodes",
      widget: "list",
      required: false,
      fields: [
        ...Zip,
      ]
    },
    {
      label: "Other zips",
      name: "otherZips",
      widget: "object",
      required: false,
      fields: [
        ...ZipFields,
      ]
    },
  ]
}

const ResidentialVoice = {
  label: "Main: Residential Voice",
  name: "main-residential-voice",
  summary: "{{blockTitle}}",
  widget: "object",
  fields: [
    { label: "Image", name: "residentialVoiceImage", widget: "file", default: "/placeholder.png" },
    { label: "Heading", name: "blockTitle", widget: "text" },
    { label: "Body", name: "residentialVoiceBody", widget: "markdown" },
    { ...SharedTextButton, label: "Button" },
  ]
}

const ScholarshipBanner = {
  label: "Main: Scholarship Banner",
  name: "main-scholarship-banner",
  summary: "{{blockTitle}}",
  widget: "object",
  fields: [
    { label: "Title", name: "blockTitle", widget: "text" },
    { label: "Subtitle", name: "bannerSubtitle", widget: "text", required: false },
    {
      label: "Buttons",
      name: "buttons",
      widget: "list",
      fields: [
        SharedTextButton,
        { label: "Button Footer", name: "buttonFooter", widget: "text", hint: "Text below the button", required: false }
      ]
    }
  ]
}

const Solutions = {
  label: "Main: Solutions",
  name: "main-solutions",
  summary: "{{blockTitle}}",
  widget: "object",
  fields: [
    { label: "Title", name: "blockTitle", widget: "text" },
    {
      label: "Categories",
      name: "solutionsCategories",
      widget: "list",
      fields: [
        { label: "Name", name: "name", widget: "text" },
        {
          label: "Icons",
          name: "icons",
          widget: "list",
          fields: [
            { label: "Icon", name: "icon", widget: "file", default: "/placeholder.png" },
            { label: "Label", name: "label", widget: "text" },
            { label: "Link type", name: "type", widget: "select", options: ["url","page"], required: false },
            { label: "Url", name: "url", required: false, widget: "text" },
            PageSelector,
          ]
        },
        { label: "Body", name: "body", widget: "markdown" },
      ]
    },
    {
      label: "Buttons",
      name: "solutionsButtons",
      widget: "list",
      fields: [
        { label: "Button Footer", name: "buttonFooter", widget: "text", hint: "Text below the button", required: false },
        SharedTextButton
      ]
    }
  ]
}

const Speeds = {
  label: "Main: Speeds",
  name: "main-speeds",
  summary: "{{blockTitle}}",
  fields: [
    { label: "Heading", name: "blockTitle", widget: "text" },
    { label: "Sub Heading", name: "subHeading", widget: "text" },
    {
      label: "Categories",
      name: "speedsCategories",
      widget: "list",
      fields: [
        { label: "Title", name: "title", widget: "text" },
        { label: "Default Disclaimer", name: "defaultDisclaimer", widget: "markdown" },
        {
          label: "Locations",
          name: "locations",
          widget: "list",
          fields: [
            { label: "Name", name: "name", widget: "text" },
            { label: "Download Speed", name: "download", widget: "number", default: 0 },
            { label: "Upload Speed", name: "upload", widget: "number", default: 0 },
            { label: "Disclaimer", name: "disclaimer", widget: "markdown" }
          ]
        },
        SharedTextButton,
      ]
    },
    { label: "Background", name: "background", widget: "file" },
    { label: "Download Icon", name: "downloadIcon", widget: "file" },
    { label: "Upload Icon", name: "uploadIcon", widget: "file" },
    { label: "Arrow Left", name: "arrowLeft", widget: "file" },
    { label: "Arrow Right", name: "arrowRight", widget: "file" },
  ]
}

const SupportCarousel = {
  label: "Main: Support Carousel",
  name: "main-support-carousel",
  summary: "{{supportCarouselSubtitle}}",
  widget: "object",
  fields: [
    { label: "Title", name: "blockTitle", widget: "text" },
    { label: "Subtitle", name: "supportCarouselSubtitle", widget: "text" },
    { label: "Body", name: "supportCarouselBody", widget: "markdown" },
    { label: "Categories", name: "categories", widget: "list" },
    { label: "Footer Text", name: "supportCarouselFooterText", widget: "text" },
    { ...SharedTextButton, label: "Footer Button" },
    {
      label: "Contact Icons",
      name: "contactIcons",
      widget: "list",
      fields: [
        { label: "Icon", name: "icon", widget: "file" },
        { label: "Text", name: "text", widget: "text", required: false },
        { label: "Path", name: "path", widget: "text", required: false }
      ]
    },
    { label: "Arrow Left", name: "arrowLeft", widget: "file" },
    { label: "Arrow Right", name: "arrowRight", widget: "file" }
  ]
}

const Support = {
  label: "Main: Support",
  name: "main-support",
  widget: "object",
  summary:"{{blockTitle}}",
  fields: [
    { 
      label: "Phone text",
      name: "phoneText",
      required: false,
      widget: "markdown",
      hint: "Text above phone number"
    },
    { label: "Phone Icon", hint: "Icon next to ", name: "phoneIcon", widget: "file", required: false },
    { label: "Title", name: "blockTitle", widget: "text" },
    { label: "Search label", name: "supportSearchLabel", widget: "text" },
    { label: "Footer Text", name: "supportFooterText", widget: "text" },
    { ...SharedTextButton, label: "Footer Button" },
    {
      label: "Support Icons",
      name: "supportIcons",
      widget: "list",
      fields: [
        {
          label: "Icon",
          name: "icon",
          widget: "object",
          fields: [
            { label: "Text", name: "text", required: false, widget: "text" },
            { label: "Icon", name: "icon", widget: "file" },
            { label: "Link To", name: "linkTo", widget: "text", required: false }
          ]
        }
      ]
    },
    {
      label: "Links Title",
      name: "supportLinksTitle",
      widget: "text"
    },
    {
      label: "Links",
      name: "supportLinks",
      widget: "list",
      fields: [
        PageSelector,
        { ...SupportSelector, required: false },
      ]
    },
    {
      label: "Faq Section Title",
      name: "supportFaqSectionTitle",
      widget: "text"
    },
    {
      label: "Faq Categories",
      name: "supportFaqCategories",
      widget: "list",
      fields: [FaqCategorySelector]
    }
  ]
}

const SupportArticle = {
  label: "Support: Article",
  name: "main-support-article",
  summary: "{{label}}",
  widget: "object",
  fields: [
    { label: "Label", name: "label", widget: "text" },
    SupportSelector
  ]
}

const SupportHowToVideos = {
  label: "Support: How-to Videos",
  name: "main-support-how-to-videos",
  summary: "{{label}}",
  fields: [
    { label: "Label", name: "label", required: false, widget: "text" },
    {
      label: "Videos",
      name: "videos",
      widget: "list",
      fields: [
        VideoSelector
      ]
    },
  ]
}

const SupportVideos = {
  label: "Support: Videos",
  name: "main-support-videos",
  summary: "{{blockTitle}}",
  fields: [
    { label: "Title", name: "blockTitle", widget: "text" },
    { label: "Description", name: "description", widget: "text"},
    {
      label: "Categories",
      name: "supportVideoCategories",
      widget: "list",
      fields: [SupportVideoCategorySelector]
    },
    { label: "More Videos Label", name: "moreVideosLabel", widget: "text" }
  ]
}

const SupportLegal = {
  label: "Support: Legal",
  name: "main-support-legal",
  summary: "{{label}}",
  fields: [
    { label: "Heading", name: "heading", required: false, widget: "text" },
    { label: "Label", name: "label", required: false, widget: "text" },
    { label: "Documents Heading", name: "documentsHeading", required: false, widget: "text" },
    {
      label: "Documents",
      name: "documents",
      widget: "list",
      fields: [
        { label: "Document", name: "document", widget: "file" },
        { label: "Label", name: "label", required: false }
      ]
    },
    { label: "Tariffs Heading", name: "tariffsHeading", required: false, widget: "text" },
    {
      label: "Tariff Items",
      name: "tariffItems",
      widget: "list",
      fields: [
        { label: "Link Prefix", name: "tariffItem", required: false, widget: "markdown" }
      ]
    }
  ]
}

const SupportPrivacyPolicy = {
  label: "Support: Privacy Policy",
  name: "main-support-privacy-policy",
  summary: "{{label}}",
  widget: "object",
  fields: [
    { label: "Heading", name: "heading", widget: "text" },
    { label: "Label", name: "label", widget: "text" },
    { label: "Privacy", name: "privacy", widget: "markdown" }
  ]
}

const Team = {
  label: "Main: Team",
  name: "main-team",
  summary: "{{blockTitle}}",
  widget: "object",
  fields: [
    { label: "Team", name: "teamTeam", widget: "select", options: ["Business","Management"] },
    { label: "Title", name: "blockTitle", widget: "text" },
    { label: "Subtitle", name: "teamSubtitle", widget: "text", required: false }
  ]
}

const TechProtect = {
  label: "Main: Tech Protect",
  name: "main-tech-protect",
  summary: "{{techProtectBottomText}}",
  widget: "object",
  fields: [
    { label: "Bottom Title", name: "blockTitle", widget: "text" },
    { label: "Bottom Text", name: "techProtectBottomText", widget: "markdown" },
    {
      label: "Feature Grid",
      name: "techProtectFeatureGrid",
      widget: "list",
      fields: [
        { label: "Icon", name: "icon", widget: "file", default: "/placeholder.png" },
        { label: "Label", name: "label", widget: "text" },
      ]
    },
    { label: "Footer Text", name: "techProtectFooterText", widget: "text" },
    {...SharedTextButton, label: "Footer Button" },
  ]
}

const Testimonials = {
  label: "Main: Testimonials",
  name: "main-testimonials",
  summary: "{{testimonialsType}}",
  widget: "object",
  fields: [
    { label: "Type", name: "testimonialsType", widget:" select", options: ["Business","Residential"]},
  ]
}

const TextAndImage = {
  label: "Main: Text and Image",
  name: "main-text-and-image",
  widget: "object",
  fields: [
    { label: "heading", name: "heading", required: false },
    { label: "text", name: "text", widget: "markdown", modes: ["rich_text"], required: false },
    {
      label: "Left or Right",
      name: "LoR",
      widget: "boolean",
      hint: "Selects whether or not an image renders on the left or right."
    },
    {
      label: "Image",
      name: "image",
      required: false,
      widget: "file",
      hint: "Leave blank if a video will be displayed"
    },
    {
      label: "Image Width",
      name: "image_width",
      required: false,
      widget: "number",
      default: 100,
      hint: "The percentage width of the image's container. The default and maximum width is 100%"
    },
    {
      label: "image_caption",
      name: "Image Caption",
      required: false,
      widget: "text",
      hint: "Leave blank if a video will be displayed"
    },
    {
      label: "Video Url",
      name: "video_url",
      required: false,
      widget: "text",
      hint: "If a video will be displayed, enter the src url from the embed link. For example, 'https://www.youtube.com/embed/VIDEO_ID"
    },
  ]
}

const Timeline = {
  label: "Main: Timeline",
  name: "main-timeline",
  summary: "{{blockTitle}}",
  widget: "object",
  fields: [
    {label: "Title", name: "blockTitle", widget: "text" },
    {
      label: "Events",
      name: "timelineEvents",
      widget: "list",
      fields: [
        { label: "Year", name: "year", widget: "text" },
        { label: "Description", name: "description", widget: "markdown" },
        { label: "Image", name: "image", widget: "file", required: false }
      ]
    },
    { label: "Arrow Right", name: "arrowRight", widget: "file" },
    { label: "Arrow Left", name: "arrowLeft", widget: "file" },
  ]
}

const WhyFiber = {
  label: "Main: Why Fiber",
  name: "main-why-fiber",
  summary: "{{blockTitle}}",
  widget: "object",
  fields: [
    { label: "Image", name: "whyFiberImage", widget: "file" },
    { label: "Title", name: "blockTitle", widget: "text" },
    { label: "Negative Top Margin", name: "negative_top_margin", widget: "boolean", required: false },
    {
      label: "Tabs",
      name: "whyFiberTabs",
      widget: "list",
      fields: [
        { label: "Icon", name: "icon", widget: "file" },
        { label: "Title", name: "title", widget: "text", required: false },
        { label: "Text", name: "text", widget: "markdown" }
      ]
    },
    {
      label: "Buttons",
      name: "whyFiberButtons",
      widget: "list",
      fields: [
        SharedTextButton
      ]
    },
    { label: "Residential", name: "residential", widget: "boolean" },
    { label: "Top Image", name: "topImage", widget: "file", required: false }
  ]
}

export const Components = [
  About,
  BandwidthEstimator,
  Banner,
  Blog,
  BusinessInternetSpeeds,
  BusinessMap,
  Careers,
  CloudCommunications,
  CloudPlans,
  CommandIq,
  Contact,
  Coverage,
  Details,
  Devices,
  FeatureChart,
  FeatureColumns,
  FeatureTabs,
  Feedback,
  FullPageText,
  HeroWithLogo,
  Hero,
  HubspotForm,
  Map,
  PageForm,
  Promotions,
  ResidentialInternet,
  ResidentialMap,
  ResidentialVoice,
  ScholarshipBanner,
  Solutions,
  Speeds,
  SupportCarousel,
  Support,
  SupportArticle,
  SupportHowToVideos,
  SupportLegal,
  SupportPrivacyPolicy,
  SupportVideos,
  Team,
  TechProtect,
  Testimonials,
  TextAndImage,
  Timeline,
  WhyFiber,
]
