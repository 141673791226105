export const SiteInfo = {
  label: "Global Settings: Site Info",
  name: "settings_site_info",
  file: "cms/content/settings/config.json",
  fields: [
    { label: "Favicon", name: "favicon", widget: "file" },
    { label: "Title", name: "title", widget: "text", hint: "The name of the site" },
    {
      label: "Site Description",
      name: "description",
      widget: "text",
      hint: "Used for SEO purposes"
    },
    {
      label: "Repository URL",
      name: "repositoryUrl", 
      widget: "text",
      required: false
    },
    {
      label: "Title Divider",
      name: "titleDivider",
      widget: "text",
      hint: "The title divider is placed between the site name and the page title in the tab/window title.",
      default: " | "
    },
    {
      label: "Author", name: "author", widget: "text", hint: "Used for SEO purposes"
    },
    {
      label: "Meta Tags", name: "metaTags", widget: "list",
      required: false
    },
    {
      label: "Google Tag Manager",
      name: "googleTagManager",
      widget: "text",
      required: false
    },
    {
      label: "Facebook Pixel",
      name: "facebookPixel",
      widget: "text",
      required: false
    }
  ]
}