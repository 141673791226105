import { FormComponents } from "../formComponents";

export const Forms = {
  label: "Forms",
  name: "forms",
  folder: "cms/content/forms",
  create: true,
  format: "json",
  identifier_field: "formTitle",
  fields: [
    {
      label: "Title",
      name: "formTitle",
      widget: "text",
      required: false,
      hint: "Title for reference, not displayed"
    },
    {
      label: "Heading",
      name: "formHeading",
      widget: "markdown",
      required: false,
      hint: "Displayed on form"
    },
    {
      label: "Subheading",
      name: "formSubheading",
      widget: "markdown",
      required: false,
      hint: "Displayed on form"
    },
    {
      label: "Submit To Email",
      name: "formSubmitToEmail",
      widget: "text",
      required: false,
    },
    {
      label: "Response Text",
      name: "responseText",
      widget: "markdown",
      required: false
    },
    {
      label: "Pages",
      name: "formPages",
      widget: "list",
      hint: "Start off with a form page to place inputs. If the form has a large number of inputs, it can be separated into multiple, progressive pages.",
      fields:[
        {
          label: "Label",
          name: "label",
          required: false,
          hint: "A label just for display"
        },
        {
          label: "Fields",
          name: "formFields",
          widget: "list",
          types: FormComponents,
        }
      ]
    }
  ]
}