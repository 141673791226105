export const ContactInfo = {
  label: "Global Settings: Contact Info",
  name: "settings_contact",
  file: "cms/content/settings/contact.json",
  fields: [
    {
      label: "Phone Number",
      name: "phoneNumber",
      widget: "text",
      hint: "Business phone number in a 10-digit format with no extra spacing or characters."
    },
    {
      label: "Address",
      name: "address",
      widget: "markdown",
      hint: "Business address"
    },
    {
      label: "Google Maps Link",
      name: "googleMapsLink",
      widget: "text"
    },
    {
      label: "Social Media Links",
      name: "socialMediaLinks",
      widget: "list",
      fields: [
        {
          label: "Label",
          name: "label",
          widget: "text",
          hint: "Label for the social media link"
        },
        {
          label: "Link",
          name: "link",
          widget: "text",
          hint: "Link to the social media profile"
        }
      ],
      hint: "Insert social media links to be used throughout the site"
    },
    { label: "Email Address", name: "email", widget: "text", required: false }
  ]
}