import { BlogArticles } from "./collections/blogArticles"
import { Careers } from "./collections/careers"
import { Faq } from "./collections/faq"
import { FaqCategories } from "./collections/faqCategories"
import { Forms } from "./collections/forms"
import { Pages } from "./collections/pages"
import { Settings } from "./collections/settings"
import { SupportArticles } from "./collections/supportArticle"
import { SupportVideoCategories } from "./collections/supportVideoCategories"
import { SupportVideos } from "./collections/supportVideoVault"
import { TeamMembers } from "./collections/teamMembers"
import { Testimonials } from "./collections/testimonials"
import { Videos } from "./collections/videos"
import cms from 'netlify-cms-app'
import React, { useEffect } from 'react'

const config = {
  backend: {
    name: "git-gateway",
    branch: "main"
  },
  load_config_file: false,
  local_backend: true,
  media_folder: "static",
  public_folder: "/",
  collections: [
    BlogArticles,
    Careers,
    Faq,
    FaqCategories,
    Forms,
    Pages,
    Settings,
    SupportArticles,
    SupportVideos,
    SupportVideoCategories,
    TeamMembers,
    Testimonials,
    Videos,
  ]
}

const AdminPage = () => {
  useEffect(() => {
    if (typeof window != 'undefined') {
      cms.init({ config })
    }
  }, [])
  return <></>
}


export default AdminPage;