import { ContactInfo } from "./contact";
import { Footer } from "./footer";
import { Header } from "./header";
import { SiteInfo } from "./siteInfo";
import { InfoButton } from "./infoButton";
import { TopBanner } from "./topBanner";
import { MobileMenu } from "./mobileMenu";

export const Settings = {
  label: "Settings",
  name: "settings",
  files: [
    ContactInfo,
    Header,
    MobileMenu,
    Footer,
    SiteInfo,
    InfoButton,
    TopBanner,
  ]
}
