import { Components } from "../components";

export const Pages = {
  label: "Pages",
  name: "pages",
  folder: "cms/content/pages",
  create: true,
  format: "json",
  fields: [
    {
      label: "Title",
      name: "title",
      hint: "The title will appear in the window/tab label after the site name."
    },
    {
      label: "Slug",
      name: "slug",
      hint: "This is the text after the domain a user would enter to navigate to this page. The slugs '/' and '404' are reserved so do not use them in places they are not currently used."
    },
    {
      label: "SEO Image",
      name: "seo_image",
      widget: "file",
      required: false,
    },
    {
      label: "Description",
      name: "description",
      required: false,
      widget: "markdown"
    },
    { label: "Include in site search", name: "siteSearch", widget: "boolean", default: true
    },
    {
      label: "Blocks",
      name: "blocks",
      widget: "list",
      types: Components
    }
  ]
}