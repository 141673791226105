import { FormSelector, PageSelector } from "../../fields";

export const InfoButton = {
  label: "Global Settings: Info Button",
  name: "settings_info_button",
  file: "cms/content/settings/info-button.json",
  fields: [
    {
      label: "Title",
      name: "title",
      widget: "text",
      required: false,
    },
    {
      label: "Link Type",
      name: "linkType",
      widget: "select",
      options: ["Text","Page","Url","Form", "Call"]
    },
    {
      label: "Text Body",
      name: "body",
      widget: "markdown",
      required: false
    },
    {...PageSelector, required: false},
    {...FormSelector, required: false},
    {
      label: "Url",
      name: "url",
      widget: "text",
      required: false
    },
    {
      label: "Call",
      name: "call",
      widget: "text",
      required: false
    },
    {
      label: "Icon",
      name: "icon",
      widget: "file",
      default: "/placeholder.png"
    },
    {
      label: "Show",
      name: "show",
      widget: "boolean",
      default: false,
    }
  ]
}