import { FaqCategorySelector } from "../fields";
import { FaqCategories } from "./faqCategories";

export const Faq = {
  label: "FAQ",
  name: "faq",
  folder: "cms/content/faq",
  create: true,
  format: "json",
  identifier_field: "question",
  fields: [
    {
      label: "Target",
      name: "target",
      widget: "select",
      options: ["Home", "Business", "All"],
      hint: "Filter for support carousel components"
    },
    { label: "Question", name: "question", widget: "text" },
    {
      label: "Category",
      name: "subcategory",
      widget: "list",
      fields: [FaqCategorySelector]
    },
    { label: "Tags", name: "tags", widget: "list" },
    { label: "Contents", name: "contents", widget: "markdown" }
  ]
}